import { Skeleton, TypewriterEffectSmooth } from '@/core/ui';
import { API } from '@/store/api';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export const HomeHeading = () => {
  const { modalityId } = useParams();
  const { i18n } = useTranslation('translation');

  const { isFetching, isLoading, data, error } = API.competitionApi.useGetCompetitionsQuery(
    { modalityId: Number.parseInt(modalityId as string, 10), locale: i18n.language },
    {
      // selectFromResult: ({ data }) => ({
      //   modality: data?.modality,
      //   appTitle: data?.app_title,
      // }),
      skip: !modalityId || !i18n.language,
    },
  );

  if (error) {
    return <p>Oh no, there was an error</p>;
  }

  if (isLoading || isFetching || !data) {
    return (
      <div className='flex flex-col justify-center items-center text-center mt-40 space-y-2'>
        <Skeleton className='w-[540px] h-[60px] my-4 -mt-2' />
        <Skeleton className='w-44 h-11' />
      </div>
    );
  }

  return (
    <div className='flex flex-col justify-center items-center text-center mt-40 space-y-2'>
      <TypewriterEffectSmooth
        transition={{ delay: 0.2 }}
        words={data.app_title
          .split(' ')
          .map((w) => ({ text: w, className: 'text-6xl line-[27.6px] font-bold tracking-[4.2px] ' }))}
        // className='my-0 ml-12'
      />

      <TypewriterEffectSmooth
        transition={{ delay: 0.4 }}
        words={data.modality
          .split(' ')
          .map((w) => ({ text: w, className: 'text-[40px] uppercase font-light tracking-[6px]' }))}
        // className='my-0 ml-12'
      />
      {/* <Typography className='text-[40px] uppercase font-light tracking-[6px]'>{modality}</Typography> */}
    </div>
  );
};

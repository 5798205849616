import { Icon } from '@/core/icon';
import { Dialog, DialogClose, DialogContent, DialogTitle, Typography } from '@/core/ui';
import { useAppDispatch, useAppSelector } from '@/store';
import { setMemberModal } from '@/store/competition';
import type { ICompetitionMembers } from '@/types';
import { useTranslation } from 'react-i18next';
import { PlayerDetail } from './member-detail';
import { Squad } from './squad';
import { TechTeam } from './techTeam';

export const CompetitionMembers = ({
  squad,
  technical_team,
  title,
}: Omit<ICompetitionMembers, 'president_info'> & { title: string }) => {
  const open = useAppSelector((s) => s.competition.memberModal);
  const dispatch = useAppDispatch();
  const { t } = useTranslation('translation');

  const setModal = (val: boolean) => () => {
    dispatch(setMemberModal(val));
  };

  return (
    <>
      <Dialog open={open} onOpenChange={setModal(false)}>
        <DialogContent
          closeJsx={
            <DialogClose
              onClick={setModal(false)}
              className='absolute right-8 top-8 rounded-sm ring-offset-background transition-opacity hover:opacity-100 focus:outline-none disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground'
            >
              <Icon name={'X_ICON'} className='p-2 w-16 h-16' color='rgb(var(--color-danger))' />
              <span className='sr-only'>Close</span>
            </DialogClose>
          }
          className='bg-transparent flex justify-center items-center overflow-hidden shadow-none h-[80%] max-w-[90%] sm:rounded-2xl'
        >
          <DialogTitle />
          <PlayerDetail />
        </DialogContent>
      </Dialog>
      <div className='bg-content1 flex flex-col space-y-24 h-screen px-32 py-16'>
        <div>
          <Typography className='text-chalk text-3xl tracking-[1.5px] leading-[28.5px]'>
            {t('competition.finalStage')} · {title}
          </Typography>
        </div>
        <div className='flex flex-row justify-between pr-16'>
          <div className='flex flex-col space-y-16'>
            <Typography className='text-chalk capitalize text-3xl tracking-[1.5px] leading-[28.5px]'>
              {t('competition.member.squad')}
            </Typography>
            <Squad players={squad} />
          </div>

          <div className='flex flex-col space-y-16'>
            <Typography className='text-chalk capitalize text-3xl tracking-[1.5px] leading-[28.5px]'>
              {t('competition.member.techTeam')}
            </Typography>
            <TechTeam techTeamMembers={technical_team} />
          </div>
        </div>
      </div>
    </>
  );
};

import { Typography } from '@/core/ui';
import { cn } from '@/lib';

interface IProps {
  value: number;
  text: string;
  className?: string;
}

export const OliveBox = ({ value, text, className }: IProps) => {
  return (
    <div className='bg-olive rounded-[20px] w-[150px] h-[210px] flex flex-col items-center'>
      <div className='mt-3'>
        <Typography className='text-chalk text-[100px] font-bold'>{value}</Typography>
      </div>
      <Typography
        className={cn(
          'text-[26px] text-chalk tracking-[1.82px] leading-[26px] text-center uppercase',
          className,
        )}
      >
        {text}
      </Typography>
    </div>
  );
};

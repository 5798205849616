import { OliveBox } from '@/core/blocks';
import { Typography } from '@/core/ui';
import type { ICompetitionStats } from '@/types';
import { useTranslation } from 'react-i18next';

const RowItem = ({ value, name }: { value: number; name: string }) => {
  return (
    <div className='flex flex-row items-center justify-center space-x-5'>
      <div className='border border-chalk rounded-lg px-5 pt-1'>
        <Typography className='text-[36px] text-chalk font-semibold'>{value}</Typography>
      </div>
      <Typography className='text-[26px] text-chalk tracking-[1.82px] uppercase leading-[26px] font-semibold'>
        {name}
      </Typography>
    </div>
  );
};

export const GameData = ({ gameData }: { gameData: ICompetitionStats['statistic_games'] }) => {
  const { draws = 0, defeats = 0, wins = 0 } = gameData;
  const { t } = useTranslation('translation');
  return (
    <>
      <Typography className='text-3xl text-chalk tracking-[1.5px] leading-[28.5px] font-semibold'>
        {t('competition.stats.game.games')}
      </Typography>
      <div className='flex flex-row space-x-12'>
        <OliveBox text={t('competition.stats.game.games')} value={draws + defeats + wins} />
        <div className='flex flex-col justify-between items-start'>
          <RowItem name={t('competition.stats.game.wins')} value={gameData.wins} />
          <RowItem name={t('competition.stats.game.draw')} value={gameData.draws} />
          <RowItem name={t('competition.stats.game.defeat')} value={gameData.defeats} />
        </div>
      </div>
    </>
  );
};

/**
 * export all store api's
 */

import { competitionApi } from './competition/api';
import { globalApi } from './global/api';
import { modalityApi } from './modality/api';

export const API = {
  globalApi,
  modalityApi,
  competitionApi,
};

export type APIKeys = keyof typeof API;
export type APIType = (typeof API)[APIKeys];

import type { INationalTeam } from '@/types';

export const nationalTeam: INationalTeam = {
  id: 1,
  name: 'Seleção A',
  gender: 'Masculino',
  modality: {
    id: 1,
    name: 'Futebol',
  },
};

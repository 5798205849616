import type { IPresident } from '@/types';

export const president: IPresident = {
  id: 1,
  name: 'FERNANDO GOMES',
  profile_image: {
    path: 'https://admin.fpf.gemadigital.com/uploads/president-profile.png',
    mime_type: 'image/png',
    caption: null,
  },
  full_body_image: {
    path: 'https://admin.fpf.gemadigital.com/uploads/president.png',
    mime_type: 'image/png',
    caption: null,
  },
};

import { envVars } from '@/config';
import { maodalities } from '@/mocks/modality';
import type { IGetModalityReq, IGetModalityRes } from '@/types';
import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchCustomBaseQuery } from '../fetcher';

export const modalityApi = createApi({
  reducerPath: 'modalityApi',
  baseQuery: fetchCustomBaseQuery({
    baseUrl: envVars.backendUrl,
    mock: false,
  }),
  tagTypes: ['modality'],
  endpoints: (builder) => ({
    getModality: builder.query<IGetModalityRes['data'], IGetModalityReq>({
      query: () => ({
        url: '/general/modalities',
        isProtected: false,
        forceMock: true,
        mockData: {
          message: 'Modality fetched Successfully',
          data: maodalities,
        },
      }),
      transformResponse: (response: IGetModalityRes) => response.data ?? {},
    }),
  }),
});

export const { useGetModalityQuery } = modalityApi;

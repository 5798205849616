import {
  CHEVRON_LEFT,
  CHEVRON_RIGHT,
  GLOBE,
  HALL_LOGO,
  HOME,
  OBJECT_LOGO,
  OBJECT_LOGO_TEXT,
  PAUSE,
  PLAY,
  PLUS,
  PLUS_CIRCLE,
  TROPHY_INFO_1,
  X_ICON,
} from './collections';

export enum ICON_NAMES {
  CHEVRON_RIGHT = 'CHEVRON_RIGHT',
  OBJECT_LOGO = 'OBJECT_LOGO',
  OBJECT_LOGO_TEXT = 'OBJECT_LOGO_TEXT',
  HALL_LOGO = 'HALL_LOGO',
  GLOBE = 'GLOBE',
  X_ICON = 'X_ICON',
  PLUS_CIRCLE = 'PLUS_CIRCLE',
  PLUS = 'PLUS',
  TROPHY_INFO_1 = 'TROPHY_INFO_1',
  HOME = 'HOME',
  CHEVRON_LEFT = 'CHEVRON_LEFT',
  PLAY = 'PLAY',
  PAUSE = 'PAUSE',
}
export type ICON_TYPE = keyof typeof ICON_NAMES | ICON_NAMES;

export const ICON_COMPONENTS: Record<ICON_NAMES, (props: React.ComponentPropsWithRef<'svg'>) => JSX.Element> =
  {
    [ICON_NAMES.OBJECT_LOGO]: OBJECT_LOGO,
    [ICON_NAMES.TROPHY_INFO_1]: TROPHY_INFO_1,
    [ICON_NAMES.CHEVRON_RIGHT]: CHEVRON_RIGHT,
    [ICON_NAMES.CHEVRON_LEFT]: CHEVRON_LEFT,
    [ICON_NAMES.HOME]: HOME,
    [ICON_NAMES.HALL_LOGO]: HALL_LOGO,
    [ICON_NAMES.OBJECT_LOGO_TEXT]: OBJECT_LOGO_TEXT,
    [ICON_NAMES.GLOBE]: GLOBE,
    [ICON_NAMES.X_ICON]: X_ICON,
    [ICON_NAMES.PLUS_CIRCLE]: PLUS_CIRCLE,
    [ICON_NAMES.PLUS]: PLUS,
    [ICON_NAMES.PAUSE]: PAUSE,
    [ICON_NAMES.PLAY]: PLAY,
  };

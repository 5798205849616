import { cn } from '@/lib';
import type { ITrophy } from '@/types';

export interface IProps {
  data: ITrophy;
}

export const Trophy = ({ data }: IProps) => {
  return (
    <div className='flex flex-col space-y-7 h-full items-center w-36'>
      <div className='h-[295px] w-[165px] flex items-end justify-center overflow-hidden'>
        <img
          className='object-cover h-full w-full max-h-full max-w-full brightness-0'
          src={data.trophy_image.path}
          alt={data.trophy_image.caption || ''}
        />
      </div>

      <span
        className={cn(
          'uppercase tracking-[1.2px] text-2xl',
          'font-united-sans font-medium text-content1',
          'text-3xl tracking-[2.1px]',
        )}
      >
        {data.year}
      </span>

      <div className='shrink-0 h-64 w-[1px] border-[1px] border-content1' />
    </div>
  );
};

import { Skeleton } from '@/core/ui';

export const CompetitionDetailLoading = () => {
  return (
    <div className='h-screen w-full bg-content1 flex flex-row justify-between px-24 relative'>
      <div className='absolute bottom-0 right-0'>
        <Skeleton className='h-[408px] w-[576px] bg-chalk/10' />
      </div>
      <div className='absolute top-0 left-0'>
        <Skeleton className='h-[294px] w-[52px] bg-danger/30' />
        <Skeleton className='h-[87px] w-[52px] bg-olive/30' />
      </div>
      <div className='flex flex-col mt-[71px] space-y-8 ml-20'>
        <Skeleton className='h-[28.5px] w-20 bg-chalk/10' />
        <div className='pt-10'>
          <Skeleton className='h-32 w-32 bg-chalk/10' />
        </div>
        <Skeleton className='h-[61.75px] w-[467px] bg-chalk/10' />
        <Skeleton className='w-[480px] h-[360px] bg-chalk/10' />
        <div className='flex flex-col space-y-[14px] pt-12'>
          <Skeleton className='h-[24.7px] w-60 bg-chalk/10' />
          <Skeleton className='h-[24.7px] w-60 bg-chalk/10' />
          <Skeleton className='h-[24.7px] w-60 bg-chalk/10' />
        </div>
      </div>
      <div className='flex justify-center items-center'>
        <Skeleton className='w-[400px] h-[865px] bg-chalk/10' />
      </div>
      <div className='flex h-full justify-center items-center'>
        <Skeleton className='w-[480px] h-[420px] bg-chalk/10 -mt-72' />
      </div>
    </div>
  );
};

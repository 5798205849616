import { Skeleton } from '@/core/ui';

export const TrophyLoading = () => {
  return (
    <div className='absolute flex flex-row w-full px-24 space-x-56 bottom-0 overflow-scroll items-end'>
      <div className='flex flex-row'>
        <div className='flex flex-col items-center w-full space-y-4 mr-4'>
          <Skeleton className='h-32 justify-end w-9' />
          <div>
            <Skeleton className='h-[25px] w-5 bg-danger/30 rounded-none' />
            <Skeleton className='h-[25px] w-5 bg-olive/30 rounded-none' />
          </div>
        </div>
        <Skeleton className='w-[2px] h-[716px]' />
      </div>

      {Array.from({ length: 5 }).map((_, index) => (
        <div key={index} className='flex justify-center items-center'>
          <div className='flex flex-col space-y-7 h-full items-center w-40'>
            <Skeleton className='w-[125px] h-[290px]' />
            <Skeleton className='h-9 w-16' />
            <Skeleton className='h-64 w-[2px]' />
          </div>
        </div>
      ))}
    </div>
  );
};

import { cn } from '@/lib';

interface IProps {
  upperClass?: string;
  lowerClass?: string;
  className?: string;
}

export const Stack = ({ upperClass, lowerClass, className }: IProps) => {
  return (
    <div className={cn(className)}>
      <div className={cn('h-[25px] w-5 bg-danger', upperClass)} />
      <div className={cn('h-[25px] w-5 bg-olive', lowerClass)} />
    </div>
  );
};

import { Stack } from '@/core/blocks';
import { Icon } from '@/core/icon';
import { Button, Dialog, DialogClose, DialogContent, DialogTitle, HtmlMarkup, Typography } from '@/core/ui';
import type { ICompetitionStats } from '@/types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BestScore } from './best-score';
import { GameData } from './game-data';
import { GoalData } from './goal-data';

export const CompetitionStats = ({
  stats,
  name,
}: {
  stats: ICompetitionStats;
  name: string;
}) => {
  const { t } = useTranslation('translation');
  const [open, setOpen] = useState(false);

  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  const isOverflowing = stats.statistic_text.length > 300;

  return (
    <div className='w-full h-screen px-28 bg-content1'>
      <div className='flex flex-col h-full space-y-16'>
        <div className='flex flex-row space-x-11'>
          <Typography className='text-chalk text-3xl font-semibold tracking-[1.5px] leading-[28.5px] mt-[71px]'>
            {t('competition.statistics')}
          </Typography>
          <img
            className='h-[480px] w-[940px] object-cover'
            src={stats.statistic_image?.path}
            alt={stats.statistic_image?.caption || ''}
          />
          <div className='flex flex-col justify-end space-y-4 mt-8 items-start'>
            <HtmlMarkup
              className={`text-chalk text-[26px] leading-[29.9px] tracking-[1.3px] max-w-[420px] ${
                isOverflowing ? 'max-h-[300px]' : 'max-h-[250px]'
              } overflow-y-auto pr-14`}
            >
              {stats.statistic_text}
            </HtmlMarkup>
            <Button className='bg-danger rounded-[32px] w-[140px] h-[72px]' onClick={openModal}>
              <Typography className='text-chalk text-2xl tracking-[1.4px] leading-[22px] uppercase'>
                Ver +
              </Typography>
            </Button>
          </div>
        </div>
        <div className='flex flex-row w-full px-24 pt-16 pb-24 bg-graphite/85 rounded-[46px] justify-between'>
          <div className='flex flex-col space-y-12'>
            <GameData gameData={stats.statistic_games} />
          </div>
          <div className='flex items-end'>
            <Stack upperClass='w-[5px] h-[137px]' lowerClass='w-[5px] h-[73px]' />
          </div>
          <div className='flex flex-col space-y-12'>
            <GoalData
              totalGame={
                stats.statistic_games?.defeats + stats.statistic_games?.draws + stats.statistic_games?.wins
              }
              goalData={stats.statistic_goals}
            />
          </div>
          <div className='flex items-end'>
            <Stack upperClass='w-[5px] h-[137px]' lowerClass='w-[5px] h-[73px]' />
          </div>
          <div className='flex flex-col space-y-8'>
            <BestScore goalScorer={stats.statistic_goal_scorers} />
          </div>
        </div>
      </div>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent
          closeJsx={
            <DialogClose
              onClick={closeModal}
              className='absolute right-8 top-8 rounded-sm ring-offset-background transition-opacity hover:opacity-100 focus:outline-none disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground'
            >
              <div className='bg-red-600 rounded-full'>
                <Icon name={'X_ICON'} className='p-2 w-16 h-16' color='#FFF' />
                <span className='sr-only'>Close</span>
              </div>
            </DialogClose>
          }
          className='bg-transparent flex justify-center items-center overflow-hidden shadow-none h-[80%] max-w-[90%] sm:rounded-2xl'
        >
          <DialogTitle />
          <div
            className='bg-[#E6F5F1] bg-trivia flex flex-row justify-between items-stretch rounded-2xl w-full h-full'
            style={{
              backgroundPosition: '-40px 100%',
              backgroundSize: '80%',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <div className='flex flex-col flex-1 mx-28 py-28 w-[600px] space-y-6 pb-28'>
              <div className='flex flex-col ml-4'>
                <Typography className='text-[45px] font-bold uppercase leading-[42px]'>
                  {t('competition.stats.curiosities')}
                </Typography>
                <Typography className='text-[45px] font-bold uppercase leading-[42px]'>{name}</Typography>
              </div>

              <div className='ml-4 overflow-y-auto mx-auto w-[600px] h-[649px]'>
                <HtmlMarkup className='text-[24px] w-[566px] font-medium leading-[30px] tracking-[1.2px]'>
                  {stats.curiosity_text}
                </HtmlMarkup>
              </div>
            </div>

            <div className='flex w-[903px] items-center'>
              <img
                src={stats.curiosity_image?.path}
                alt={stats.curiosity_image?.caption || ''}
                className='w-full h-full object-cover'
              />
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

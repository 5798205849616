import { PlayerCard } from '@/core/blocks';
import { Carousel, CarouselContent, CarouselDots, CarouselItem } from '@/core/ui';
import { EMember, type ITechTeamMember } from '@/types';
import { splitArray } from '@/utils';

export const TechTeam = ({ techTeamMembers }: { techTeamMembers: ITechTeamMember[] }) => {
  if (techTeamMembers.length < 6) {
    return (
      <div className='grid grid-cols-1 gap-y-6'>
        {techTeamMembers.map((p) => (
          <PlayerCard
            playerId={p.id}
            type={EMember.TECH_TEAM_MEMBER}
            className='col-span-1'
            key={p.id}
            name={p.name}
            position={p.role}
            image={p.member_image}
            link={p.has_competition_details}
          />
        ))}
      </div>
    );
  }

  const list = splitArray(techTeamMembers, 5);

  return (
    <Carousel
      opts={{
        align: 'start',
      }}
      orientation='vertical'
      className='w-full h-full max-w-[625px]'
    >
      <CarouselContent className='-mt-1 h-[672px]'>
        {list.map((items, index) => (
          <CarouselItem key={index} className='pt-1 md:basis-1/1'>
            <div className='grid grid-cols-1 gap-y-6'>
              {items.map((p) => (
                <PlayerCard
                  type={EMember.TECH_TEAM_MEMBER}
                  playerId={p.id}
                  className='col-span-1'
                  key={p.id}
                  name={p.name}
                  position={p.role}
                  image={p.member_image}
                  link={p.has_competition_details}
                />
              ))}
            </div>
          </CarouselItem>
        ))}
      </CarouselContent>
      <CarouselDots />
    </Carousel>
  );
};

const ROUTES = {
  MAIN: '/',
  HOME: { MAIN: '', VIEW: '/:modalityId' },
  LOGIN: '/login',
  COMPETITION: {
    MAIN: 'competitions',
    LIST: '/:modalityId/competitions',
    VIEW: '/:modalityId/competition/:competitionId',
  },
};

export { ROUTES };

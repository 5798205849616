import { ROUTES } from '@/config';
import { Loading } from '@/core/blocks';
import { API } from '@/store/api';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation } from 'react-router-dom';

const Redirect = () => {
  const { i18n } = useTranslation();
  const location = useLocation(); // to track the current location for redirecting state
  const { error, isLoading, isFetching, data } = API.modalityApi.useGetModalityQuery(
    { locale: i18n.language },
    { skip: !i18n.language },
  );

  if (error) {
    return <p>Oh no, there was an error</p>;
  }

  if (isLoading || isFetching || !data) {
    return <Loading />;
  }

  const modality = data[0];

  if (modality?.id) {
    return (
      <Navigate
        to={`${ROUTES.HOME.MAIN}/${modality.id}/${ROUTES.COMPETITION.MAIN}`}
        state={{ from: location }}
        replace
      />
    );
  }

  return <div>NO Modality Found</div>;
};

export default Redirect;

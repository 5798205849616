import { Stack } from '@/core/blocks';
import { HtmlMarkup, Skeleton, Typography } from '@/core/ui';
import { cn } from '@/lib';
import { useAppSelector } from '@/store';
import { API } from '@/store/api';
import { EMember, type IGetPlayerByIdRes, type IGetTechMemberByIdRes } from '@/types';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

function isTechMember(
  data: IGetPlayerByIdRes['data'] | IGetTechMemberByIdRes['data'],
): data is IGetTechMemberByIdRes['data'] {
  return 'member' in data;
}

const useMemberData = () => {
  const { competitionId } = useParams();
  const { i18n } = useTranslation();
  const id = Number.parseInt(competitionId as string, 10);
  const member = useAppSelector((s) => s.competition.member);

  const [fetchPlayer, response1] = API.competitionApi.useLazyGetPlayerDetailQuery();
  const [fetchTechMember, response2] = API.competitionApi.useLazyGetTechMemberDetailQuery();

  useEffect(() => {
    if (!member || !competitionId || !i18n) {
      return;
    }

    if (member.type === EMember.SQUAD) {
      fetchPlayer({
        memberId: member.id,
        competitionId: id,
        locale: i18n.language,
      });
    } else {
      fetchTechMember({
        memberId: member.id,
        competitionId: id,
        locale: i18n.language,
      });
    }
  }, []);

  if (member?.type === EMember.SQUAD) {
    return response1;
  }

  return response2;
};

export const PlayerDetail = () => {
  const { error, isFetching, isLoading, data } = useMemberData();

  const member = useMemo(() => {
    if (!data?.competition) {
      return null;
    }

    if (isTechMember(data)) {
      return {
        extra_info: data.member?.extra_info,
        description: data.member?.description,
        image: data.member.member_image,
        position: data.member.role,
        name: data.member.name,
        club: data.member.club,
      };
    }
    return {
      extra_info: data.player?.extra_info,
      description: data.player?.description,
      image: data.player.player_image,
      position: data.player.position,
      name: data.player.name,
      club: data.player.club,
    };
  }, [data]);

  if (error) {
    return <p>Oh no, there was an error</p>;
  }

  if (isFetching || isLoading || !data) {
    return (
      <div className='bg-gradient-to-r from-olive/90 via-apple to-apple rounded-2xl h-full w-full'>
        <div className={cn('flex flex-row items-center h-full w-full')}>
          <div className='flex flex-col w-full items-center justify-between space-y-10 basis-5/12 p-[105px]'>
            <Skeleton className='rounded-full w-[361px] h-[361px]' />
            <div className='flex flex-col space-y-8 items-center'>
              <Skeleton className='h-[80px] w-64' />
              <Skeleton className='h-[38px] w-32' />
            </div>
          </div>
          <div className='w-full basis-7/12 pl-0 p-[15px]'>
            <div className='flex flex-col space-y-8'>
              <Skeleton className='h-[50px] w-96' />
              <Skeleton className='h-[275px] w-[664px]' />
            </div>
            {/* <div className='flex flex-col space-y-[38px]'>
              {Array.from({ length: 3 }).map((_, index) => (
                <div key={index} className='flex justify-between items-center'>
                  <div className='flex space-x-6'>
                    <Stack upperClass='w-[10px] h-[20px]' lowerClass='w-[10px] h-[16px]' />
                    <Skeleton className='h-[50px] w-72' />
                  </div>
                  <Skeleton className='h-[50px] w-20' />
                </div>
              ))}
            </div> */}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='bg-gradient-to-r from-olive/90 via-apple to-apple rounded-2xl h-full w-full'>
      <div className={cn('flex flex-row items-center h-full w-full bg-player bg-no-repeat')}>
        <div className='flex flex-col w-full items-center justify-between space-y-10 basis-5/12 p-[105px]'>
          {member?.image.path ? (
            <img
              alt={member?.image.caption || ''}
              src={member?.image.path}
              className='bg-chalk rounded-full w-[361px] h-[361px] object-cover border-[5px] border-white shadow-md'
            />
          ) : (
            <img
              alt='player'
              src='/default.png'
              className='bg-chalk rounded-full w-[361px] h-[361px] object-cover'
            />
          )}
          <div className='max-w-[267px] flex flex-col space-y-8 text-center'>
            <Typography className='text-[55px] font-bold leading-[52.25px] tracking-[3.85px] uppercase'>
              {member?.name}
            </Typography>
            <div className='flex flex-col space-y-2'>
              <Typography className='text-[32px] font-semibold leading-[32px] tracking-[1.6px] uppercase'>
                {member?.position}
              </Typography>
              <Typography className='text-[32px] font-medium leading-[32px] tracking-[1.6px] uppercase'>
                {member?.club}
              </Typography>
            </div>
          </div>
        </div>
        <div className='w-full basis-7/12 space-y-12 pl-0 p-[105px]'>
          <div className='flex flex-col space-y-8'>
            <div className='max-w-2xl'>
              <Typography className='text-[45px] font-semibold leading-[42.75px] tracking-[3.15px] uppercase'>
                {data.competition.name}
              </Typography>
            </div>
            <div className='flex flex-col max-w-[664px] space-y-7'>
              <HtmlMarkup className='text-2xl leading-[27.6px] tracking-[1.2px]'>
                {member?.description}
              </HtmlMarkup>
            </div>
          </div>
          <div className='flex flex-col space-y-[38px]'>
            {member?.extra_info.map((i) => (
              <div key={i.title} className='flex justify-between items-center'>
                <div className='flex space-x-6'>
                  <Stack upperClass='w-[10px] h-[23px]' lowerClass='w-[10px] h-[13px]' />
                  <Typography className='text-[45px] font-semibold leading-[45px] tracking-[3.15px] uppercase'>
                    {i.title}
                  </Typography>
                </div>
                <Typography className='text-[50px] font-bold leading-[50px] tracking-[3.5px] uppercase'>
                  {i.content}
                </Typography>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

import { cn } from '@/lib';
import dom from 'dompurify';
import type React from 'react';
import { useEffect, useRef } from 'react';

function createMarkup(dirty: string) {
  return dom.sanitize(dirty);
}

export function HtmlMarkup1({ children, ...props }: React.ComponentProps<'span'>) {
  const spanRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (spanRef.current) {
      spanRef.current.innerHTML = createMarkup(children as string);
    }
  }, [spanRef]);

  return (
    <>
      <span ref={spanRef} {...props} />
    </>
  );
}

export function HtmlMarkup({ children, className, ...props }: React.ComponentProps<'div'>) {
  return (
    <>
      <div
        className={cn('font-united-sans font-medium prose', className)}
        // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
        dangerouslySetInnerHTML={{ __html: createMarkup(children as string) }}
        {...props}
      />
    </>
  );
}

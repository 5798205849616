import type { IModality } from '@/types';

export const maodalities: IModality[] = [
  {
    id: 1,
    name: 'Footabll',
  },
  {
    id: 2,
    name: 'Futsal',
  },
  {
    id: 3,
    name: 'Soccer',
  },
];

export const range = (from: number, to: number, step = 1) => {
  const rangee = [];
  for (let i = from; i <= to; i += step) {
    rangee.push(i);
  }
  return rangee;
};

export const splitArray = <T>(arr: T[], count: number) => {
  const result = [];

  for (let i = 0; i < arr.length; i += count) {
    result.push(arr.slice(i, i + count));
  }

  return result;
};

import { CompetitionList, HomeHeading } from '@/components/home';
import { Header } from '@/layout';

const Home = () => {
  return (
    <div className='flex flex-col h-screen bg-apple bg-cover bg-halls relative'>
      <div className=''>
        <Header />
      </div>
      <HomeHeading />
      <CompetitionList />
    </div>
  );
};

export default Home;

import { GallerySlider } from '@/core/blocks';
import { Icon } from '@/core/icon';
import { Dialog, DialogClose, DialogContent, DialogTitle, DialogTrigger, Typography } from '@/core/ui';
import type { ICompetitionGallery, ICompetitionHighlight } from '@/types';
import { useTranslation } from 'react-i18next';

export const Gallery = ({
  highlight,
  gallery,
}: {
  highlight: ICompetitionHighlight['highlight'];
  gallery: ICompetitionGallery['gallery'];
}) => {
  const { t } = useTranslation('translation');

  return (
    <div className='flex flex-row h-screen bg-content1 justify-between relative'>
      <div className='space-y-24 ml-[220px]'>
        <div className='flex flex-row space-x-[105px]'>
          <div className='mt-[71px]'>
            <Typography className='text-chalk text-3xl leading-[28.5px] tracking-[1.5px] font-semibold'>
              {t('competition.gallery.text')}
            </Typography>
          </div>
          <img
            className='h-[30rem] w-[30rem] object-cover'
            src={highlight.media_1?.path}
            alt={highlight.media_1?.caption || ''}
          />
        </div>
        <div className='flex flex-row space-x-[28px]'>
          <img
            className='h-[504px] w-[480px] object-cover'
            src={highlight.media_2?.path}
            alt={highlight.media_2?.caption || ''}
          />
          <div className='max-w-[164px]'>
            <Typography className='text-chalk text-[26px] leading-[29.9px] tracking-[1.3px]'>
              {highlight.media_2?.caption}
            </Typography>
          </div>
        </div>
      </div>
      <div>
        <img
          src={highlight.media_3?.path}
          alt={highlight.media_3?.caption || ''}
          className='h-full w-[952px] object-cover'
        />
      </div>
      {!!gallery.length && (
        <Dialog>
          <DialogTrigger asChild>
            <div className='absolute bottom-[120px] right-48'>
              <button
                type='button'
                className='text-chalk flex items-center justify-center space-x-[10px] bg-danger rounded-full py-[28px] px-[50px]'
              >
                <Typography className='pt-1 text-chalk text-3xl leading-[28.5px] uppercase'>ver</Typography>
                <Icon name='PLUS' />
              </button>
            </div>
          </DialogTrigger>
          <DialogContent
            closeJsx={
              <DialogClose className='absolute right-8 top-8 rounded-sm ring-offset-background transition-opacity hover:opacity-100 focus:outline-none disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground'>
                <Icon name={'X_ICON'} className='bg-danger rounded-full p-2 w-16 h-16' color='white' />
                <span className='sr-only'>Close</span>
              </DialogClose>
            }
            className='bg-transparent flex justify-center items-center shadow-none'
          >
            <DialogTitle />
            <GallerySlider slides={gallery} />
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

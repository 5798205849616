import { OliveBox, PlayerProfile } from '@/core/blocks';
import { Typography } from '@/core/ui';
import type { ICompetitionStats } from '@/types';
import { useTranslation } from 'react-i18next';

export const BestScore = ({
  goalScorer,
}: {
  goalScorer: ICompetitionStats['statistic_goal_scorers'];
}) => {
  const goals = goalScorer?.[0]?.goals ?? 0;
  const { t } = useTranslation('translation');

  return (
    <>
      <Typography className='text-3xl text-chalk tracking-[1.5px] font-semibold'>
        {t('competition.stats.goal.bestScore')}
      </Typography>
      <div className='flex flex-row space-x-10 items-center'>
        <PlayerProfile data={goalScorer ?? []} />
        <div>
          <OliveBox
            text={t('competition.stats.goal.goalScored')}
            value={goals ?? 0}
            className='-mt-4 text-xl leading-5 tracking-[1.4px] max-w-24'
          />
        </div>
      </div>
    </>
  );
};
